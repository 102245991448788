import { cloneElement, useMemo } from 'react';
import { Form } from 'antd';

//* Helpers
import { formValidation } from '@/helpers';

//* Components
import Translate from '../../Translate';

//* Style
import FormItemStyle from './style';

const FormItem = ({ label, name, type, validationType, className, required = true, min, max, message, autocomplete = 'on', extra, extraChild, rules = [], options = {}, customRule = false, ...props }) => {
    const newRules = useMemo(() => {
        if (customRule) {
            return rules;
        }

        let r = [...rules];

        if (type == 'email') {
            r.push({ transform: (value) => value.trim() });
        }

        const typeObj = type ? { type } : '';
        r[0] = { ...r?.[0], message: '', required, ...typeObj };

        const params = r[0];

        if (required || validationType) {
            r[1] = ({ getFieldValue }) => ({
                validator(_, value) {
                    const validType = validationType || params.type || 'string';
                    let validate;

                    let settings;
                    settings = min || typeof min === 'number' ? { ...settings, min: min } : settings;
                    settings = max ? { ...settings, max: max } : settings;

                    if (name === 'password_confirmation') {
                        validate = formValidation(Translate, { pass: getFieldValue('password'), c_pass: value }, 'c_password', value ? message : name + 'IsRequired');
                    } else {
                        validate = formValidation(Translate, value, validType, message || name + 'IsRequired');
                    }

                    if (validate === true) {
                        return Promise.resolve();
                    }

                    return Promise.reject(validate);
                },
            });
        }

        return r;
    }, [name, type, required, validationType, rules, min, max, customRule]);

    return (
        <FormItemStyle>
            <Form.Item
                name={name}
                hasFeedback
                rules={newRules}
                label={label || null}
                className={className || ''}
                onChange={props.onChange || null}
                extra={extra && (extraChild || extra)}
                {...options}>
                {cloneElement(props.children, { autoComplete: autocomplete })}
            </Form.Item>
        </FormItemStyle>
    );
};

export default FormItem;
